/** @format */

import {
  ABSENCE_ERROR,
  ABSENCE_USER_CLEAR,
  EXPORT_ATTENDANCE_SCORE_CLEAR,
  ABSENCES_COMPLETION,
  ABSENCES_LOAD,
  ABSENCESALERT_LOAD,
  SESSION_ABSENCE_UPDATE,
  SESSION_LOAD,
  STATISTICS_ABSENCE_RATE_LOAD,
  STATISTICS_ABSENCES_LOAD,
  STATISTICS_COORDINATOR_ABSENCE_COMPLETION_LOAD,
  STATISTICS_GRADES_LOAD,
  ABSENCES_STUDENT_LOAD,
  ATTENDANCE_SCORE_LOAD,

} from '../actions/types';

const initialState = {
  users: [],
  count: 0,
  defaultIDX: 0,
  selectorFields: {
    field: '',
    level: '',
    course: '',
    classroom: '',
  },
  sessions: [],
  absencesCompletion: [],
  statisticsAbsences: [],
  absenceRate: [],
  coordinatorStatistics: [],
  grades: [],
  studentAbsences: {},
  attendanceScore:[],
};

export default function absence(state = initialState, action) {
  const { type, payload } = action;
  const { sessions } = state;
  const [currentSession] = sessions.filter(({ _id }) => _id === payload?._id);

  switch (type) {
    case ABSENCES_LOAD:
      return { ...state, users: payload };
    case ABSENCESALERT_LOAD:
      return { ...state, count: payload.count, users: payload.users };
    case ABSENCES_COMPLETION:
      return { ...state, absencesCompletion: payload };
    case SESSION_LOAD:
      return {
        ...state,
        sessions: payload.map((session) => ({
          _id: session._id,
          absences: session.absences,
          classCode: session.classCode,
          endDate: session.endDateTime,
          startDate: session.startDateTime,
          courseLabel: session.easylmdCourseName,
          isFilled: session.absencesAreFilled,
          executed: session.executed,
          easylmdSessionId: session.easylmdSessionId,
          cancelled: session.cancelled
        })),
      };
    case SESSION_ABSENCE_UPDATE:
      return {
        ...state,
        sessions: [
          ...sessions.filter(({ _id }) => _id !== payload._id),
          {
            ...currentSession,
            isFilled: payload.absencesAreFilled,
          },
        ],
      };
    case STATISTICS_ABSENCES_LOAD:
      return { ...state, statisticsAbsences: payload };
    case STATISTICS_ABSENCE_RATE_LOAD:
      return { ...state, absenceRate: payload };
    case STATISTICS_COORDINATOR_ABSENCE_COMPLETION_LOAD:
      return { ...state, coordinatorStatistics: payload };
    case STATISTICS_GRADES_LOAD:
      return { ...state, grades: payload };
    case ABSENCE_ERROR:
      return state;
    case ABSENCE_USER_CLEAR:
      return { ...state, users: [] };
    case ABSENCES_STUDENT_LOAD:
      return { ...state, studentAbsences: payload };
    case ATTENDANCE_SCORE_LOAD:
      return { ...state, attendanceScore: payload?.students };
    case EXPORT_ATTENDANCE_SCORE_CLEAR:
    return { ...state, attendanceScore: [] };
    default:
      return state;
  }
}
